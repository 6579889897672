<script>

    import appConfig from "@/app.config";
    import PageHeader from "@/components/structure/page-header";
    import helper from "@/assets/helpers/helper";
    /**
     * Projects-grid component
     */
    export default {
        page: {
            title: "Badges",
            meta: [{name: "description", content: appConfig.description}]
        },
        name: 'Badges',
        components: { PageHeader},
        data() {
            return {
                badges: [],
                load:false,
                title: "Badges",
            };
        },
        created() {
            this.loadBadges()
        },
        methods: {
            show(b){
              this.$store.commit('badge',b)
                 this.$root.$emit('bv::show::modal', 'modal-share-badge')
            },
            async loadBadges() {
                this.badges = await helper.getAll('me/badges');
                this.load = true

            },
        }
    };
</script>

<template>
    <div>
        <PageHeader :title="$t('badges.text')"/>
        <div class="row" v-if="load">
            <div v-for="badge in badges" :key="badge.id" class="col-xl-3 col-sm-6">
                <div class="card" :class="{'my_badge': badge.done_count == 1,'disabled': badge.done_count == 0}"  @click="show(badge)" role="button">
                    <div class="card-body ">
                      <div class="row mt-2 ">

                        <h3 class="text-truncate  m-auto" >{{badge.title}}</h3>
                      </div>
                      <div class="row mt-3 ">
                        <div class="m-auto">
                          <b-avatar :src="badge.avatar" size="10rem"></b-avatar>
                        </div>
                      </div>

                      <div class="row mt-3 ">
                        <p class="text-muted  m-auto">{{badge.description}}</p>
                      </div>

                    </div>
                    <div class="px-4 py-2 border-top text-center font-size-14">
                        <ul class="list-inline mb-0">

                            <li v-b-tooltip.hover.top class="list-inline-item mr-3" :title="$t('badges.date')" v-if="badge.done_count">
                                <i class="bx bx-calendar mr-1"></i>
                                {{new Date(badge.created_at).toLocaleDateString()}}
                            </li>
                            <li v-b-tooltip.hover.top class="list-inline-item mr-3" :title="$t('badges.users')">
                                <i class="bx bxs-user-check mr-1 "></i>
                                {{badge.done_count}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class=" col-xl-3 col-sm-6 mb-4" v-for="n in 12" :key="n">
              <div class="card" >
                <div class="card-body ">
                  <div class="row mt-2 ">

                    <b-skeleton class="mb-2 mx-auto" width="50%"></b-skeleton>


                  </div>
                  <div class="row mt-3 ">
                    <div class="m-auto">
                      <b-avatar size="10rem" ></b-avatar>
                      </div>
                  </div>

                  <div class="row mt-3 ">
                    <b-skeleton class="mb-2" width="100%"></b-skeleton>
                    <b-skeleton class="mb-2" width="100%"></b-skeleton>
                    <b-skeleton class="mb-2" width="100%"></b-skeleton>
                  </div>

                </div>

              </div>


            </div>

        </div>
        <!-- end ro w -->
    </div>
</template>
<style scoped>
    .card{
      filter: opacity(50%);
    }
    .card:hover{
      transform: scale(1.04);
      background-color: #2A2E51;
    }
.my_badge{
filter: opacity(1);
  background-color: #2a2e51;
}
.disabled{
  pointer-events:none;
}

</style>